import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Timeline from "../components/timeline/timeline"
import Posts from "../components/posts/posts"
import Section from "../components/section/section"

import timelineData from "../../data/timelineData"

const IndexPage = ({ data }) => (
  <Layout
    heroFade
    heroCover={data.cover}
    heroContent={
      <>
        <h1 className="huge">Hi there,</h1>
        <h1 className="huge">
          I'm <strong className="text-primary">Sander</strong>
        </h1>
        <h3>Learn more about me and my projects.</h3>
      </>
    }
  >
    <SEO title="Home" keywords={[`sander`, `vervaeke`, `projects`, `Kortrijk`, `full stack`, `web developer`, `developer`]} />
    <article id="home">
      <section id="about">
        <h1>About me</h1>
        <p>
          Let me introduce myself. My name is <span>Sander Vervaeke</span> and I’m a <span>full stack web developer</span> from <span>Kortrijk, Belgium</span>.
          I’m very passionate about programming and making things in general. Currently I work as a <span>mobile, web and IoT consultant at delaware</span>.
          Besides programming and being a maker, I also really enjoy cooking and fencing.
        </p>
        <p>
          I have experience in lots of different programming languages, but currently <span>I’m learning Rust</span>. Why Rust you may ask? Well as I mentioned
          earlier my passion lies in making things and Rust currently allows me the create the most things without having to learn a new language every time.
          Rust not only allows me to make the most things, but also make them blazingly fast and memory efficient.
        </p>
        <p>
          Don’t hesitate to <Link to="/contact">contact me</Link> if you want to work on a project together, or just want to chat.
        </p>
      </section>
      <section id="tech">
        <h1>Technical expertise</h1>
        <p>
          I'm a <span>fullstack developer</span> able to <span>create full solutions start to finish</span> with experience in different technology stacks.
          Here are the technologies and tools I have the most experience with:
        </p>
        <Section>
          <div>
            <h3>Design</h3>
            <p>Adobe Photoshop, Adobe Illustrator, Adobe XD</p>
          </div>
          <div>
            <h3>Backend</h3>
            <p>.NET Core (C#), Express (Node), Actix (Rust)</p>
          </div>
          <div>
            <h3>Frontend</h3>
            <p>React, Angular, Svelte, Web assembly (Rust), CSS (Less, Sass, Styled components), Redux, JavaScript, TypeScript</p>
          </div>
          <div>
            <h3>Storage</h3>
            <p>My SQL, PostgreSQL, MongoDB</p>
          </div>
          <div>
            <h3>Platform</h3>
            <p>React Native, PWA, Electron, Tauri</p>
          </div>
          <div>
            <h3>Infrastructure</h3>
            <p>Azure, AWS, Docker, Linux</p>
          </div>
          <div>
            <h3>Machine learning</h3>
            <p>TensorFlow (Python, Js), Keras, Azure ML</p>
          </div>
        </Section>
      </section>
      <Timeline id="experience" title="Education and Experience" data={timelineData} />
      <Posts id="projects" title="Projects" posts={data.allMdx.projects} />
    </article>
  </Layout>
)

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "pluto.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }, filter: { frontmatter: { category: { in: "project" } } }) {
      projects: edges {
        node {
          id
          frontmatter {
            title
            slug
            date(formatString: "MMM DD, YYYY")
            cover {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
