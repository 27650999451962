import { useState, useEffect } from "react"

const useOnScreen = (ref, rootMargin = "0px", triggerOneTime) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const refToObserve = ref && ref.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && triggerOneTime) {
          setIsVisible(true)
        } else if (!triggerOneTime) {
          setIsVisible(entry.isIntersecting)
        }
      },
      { rootMargin }
    )
    if (refToObserve) {
      observer.observe(refToObserve)
    }
    return () => {
      observer.unobserve(refToObserve)
    }
  }, [ref, rootMargin, triggerOneTime])

  return isVisible
}

export default useOnScreen
