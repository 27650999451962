import React from "react"
import PropTypes from "prop-types"
import "./section.less"

const Section = ({ id, layout, children, ...sectionProps }) => {
  return (
    <div id={id} className={`grid ${layout}`} {...sectionProps}>
      {children}
    </div>
  )
}

Section.defaultProps = {
  layout: "",
}

Section.propTypes = {
  id: PropTypes.string,
  layout: PropTypes.string
}

export default Section
