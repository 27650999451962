import React from "react"

import { faGraduationCap, faUserGraduate, faBook, faFlask, faUserTie, faBriefcase } from "@fortawesome/free-solid-svg-icons"

const data = [
  {
    date: "2007 - 2011",
    role: "Student",
    event: "Modern languages & Economics",
    place: "Sint-Jan Berchmans",
    icon: faBook,
  },
  {
    date: "2011 - 2013",
    role: "Student",
    event: "Industrial ICT",
    place: "VTI Waregem",
    icon: faBook,
  },
  {
    date: "2013 - 2014",
    role: "Student",
    event: "App Development",
    place: "Hogeschool Vives",
    icon: faGraduationCap,
  },
  {
    date: "2014 - 2018",
    role: "Student",
    event: "New Media & Communication Technology",
    place: "Hogeschool West-Vlaanderen (Howest)",
    icon: faGraduationCap,
    description: (
      <>
        <p>Following the smart technology creator trajectory, which involves machine learning, AI and IOT.</p>
      </>
    ),
  },
  {
    date: "2016 - 2017",
    role: "Student",
    event: "Full stack developer",
    place: "Opsoro kickstarter",
    icon: faFlask,
    description: (
      <>
        <p>Creating applications for Opsoro, the open platform for creating social robots.</p>
      </>
    ),
  },
  {
    date: "2016 - 2017",
    role: "Student job",
    event: "Frontend developer",
    place: "Willy de Nolf bvba",
    icon: faFlask,
    description: (
      <>
        <p>Develop a custom frontend for their b2b web shop on the Odoo open Erp and CRM platform.</p>
      </>
    ),
  },
  {
    date: "2017 - 2018",
    role: "Student",
    event: "Entrepreneur",
    place: "Otwoo",
    icon: faUserTie,
    description: (
      <>
        <p>
          With our startup we want to revolutionize shopping by bringing the online shopping experience to your local store creating a hybrid store concept.
        </p>
      </>
    ),
  },
  {
    date: "2017 - 2018",
    role: "Intern",
    event: "Full stack developer",
    place: "Barco",
    icon: faBriefcase,
    description: (
      <>
        <p>Redesign & refactor the web application to process leads, to be scalable, performant and user friendly.</p>
      </>
    ),
  },
  {
    date: "2018",
    role: "Student",
    event: "Graduated NMCT",
    place: "Hogeschool West-Vlaanderen (Howest)",
    icon: faUserGraduate,
  },
  {
    date: "2018 - Current",
    role: "Consultant",
    event: "Full stack developer",
    place: "delaware",
    icon: faBriefcase,
    description: (
      <>
        <p>Currently working as full stack developer @delaware consulting in the mobile, web & IoT team.</p>
      </>
    ),
  },
]

export default data
