import React, { useRef } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSpring, animated, config } from "react-spring"

import useOnScreen from "../../../hooks/useOnScreen"

const Node = ({ data: { event, role, date, place, description, icon } }) => {
  const nodeRef = useRef(null)
  const visible = useOnScreen(nodeRef, "-50%", true)

  const badgeSpring = useSpring({
    opacity: visible ? 1 : 0,
    transform: visible ? "scale(1)" : "scale(0)",
    config: config.wobbly,
  })
  const eventSpring = useSpring({
    delay: visible ? 300 : 0,
    opacity: visible ? 1 : 0,
    transform: visible ? "translate3d(0, 0, 0)" : "translate3d(-300px, 0, 0)",
  })

  return (
    <div ref={nodeRef} className="node">
      <animated.div className="event" style={eventSpring}>
        <div>
          <h3 className="text-disabled">{role}</h3>
          <h3 className="text-primary">{date}</h3>
        </div>
        <h2 className="text-primary">{event}</h2>
        <p className="text-disabled">{place}</p>
        {description}
      </animated.div>
      <animated.div className="badge" style={badgeSpring}>
        <FontAwesomeIcon icon={icon} />
      </animated.div>
    </div>
  )
}

Node.propTypes = {
  data: PropTypes.object,
}

export default Node
