import React from "react"
import PropTypes from "prop-types"
import "./timeline.less"

import Node from "./node/node"

const Timeline = ({ title, id, data }) => {
  return (
    <section id={id} className="timeline">
      <h1>{title}</h1>
      <div className="node start" />
      {data.map((data, index) => (
        <Node data={data} key={index}/>
      ))}
      <div className="node end" />
    </section>
  )
}

Timeline.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  id: PropTypes.string,
}

export default Timeline
